import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgressBarService {
  private progressBarSubject = new Subject<number>();
  progressBar$ = this.progressBarSubject.asObservable();

  constructor() {
  }

  updateProgressBarValue(value: number) {
    this.progressBarSubject.next(value);
  }
}
